// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-js": () => import("/home/circleci/project/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-about-js": () => import("/home/circleci/project/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-category-js": () => import("/home/circleci/project/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-project-js": () => import("/home/circleci/project/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-joep-js": () => import("/home/circleci/project/src/templates/joep.js" /* webpackChunkName: "component---src-templates-joep-js" */),
  "component---src-pages-nl-index-js": () => import("/home/circleci/project/src/pages/nl/index.js" /* webpackChunkName: "component---src-pages-nl-index-js" */),
  "component---src-pages-projects-index-js": () => import("/home/circleci/project/src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-404-js": () => import("/home/circleci/project/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/circleci/project/.cache/data.json")

